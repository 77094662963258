
nav{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 10;
    backdrop-filter: blur(5px);
    padding: 1em;
    background: rgba(0, 0, 0, 0.3);
    
}

.nav__logo {
    flex: 1;
}

.nav__right {
    list-style: none;
    display: flex;
    column-gap: 20px;
    justify-content: flex-end;
}

nav a{
    display: inline-block;
    text-decoration: none;
    position:relative;
    color: #fff;
    
}

nav a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    background-color: #fff;
    transform-origin: right;
    transition: transform .5s;
}

.nav__right a:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

nav a.active {
    color: var(--color-bg);
}

#toggler,
.navbar label{
    display: none;
}



/* ========= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .menu{
        width: 100%;
        max-height: 0;
        overflow: hidden;
    }
    
    .list{
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
 
    #hamburger:checked ~ .menu{
        max-height: 100%;
    }
    
    .hamburger {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;       
    }

    .hamburger .line {
        display: block;
        width: 40px;
        height: 3px;
        background-color: #ecf0f1;
        margin-block: 10px;
        border-radius: 4px;
        transition: transform .5s;
        opacity: .25s;
    }

    .hamburger.active .line:nth-child(1) {
        transform: translateY(13px)
        rotate(45deg);
    }

    .hamburger.active .line:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .line:nth-child(3) {
        transform: translateY(-13px)
        rotate(-45deg);
    }
}
