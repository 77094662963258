@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
header {
    height: 100vh;
    padding-top: 15rem;
    overflow: hidden;
}
/*
.me {
    width: 700px;
    clip-path: circle();
    max-width: 100%;
    margin-right: 20px;
}
*/
p{
    display: inline-block;
}

.header__container {
    display: flex;
    padding-left: 11rem;
}
.name {
    color: var(--color-primary);
}


/* ================= CTA ====================*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    justify-content: left;
}

/* ========= LINES ========== */
.line {
    width: 60%;
    height: 1px;
    border: 0 none;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--color-white);
}

/* ========= MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* ========= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        margin: 0;
        padding: 0;
    }
}

nav{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    padding: 1em;
    background: rgba(0, 0, 0, 0.3);
    
}

.nav__logo {
    flex: 1 1;
}

.nav__right {
    list-style: none;
    display: flex;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    justify-content: flex-end;
}

nav a{
    display: inline-block;
    text-decoration: none;
    position:relative;
    color: #fff;
    
}

nav a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    background-color: #fff;
    -webkit-transform-origin: right;
            transform-origin: right;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
}

.nav__right a:hover::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: left;
            transform-origin: left;
}

nav a.active {
    color: var(--color-bg);
}

#toggler,
.navbar label{
    display: none;
}



/* ========= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .menu{
        width: 100%;
        max-height: 0;
        overflow: hidden;
    }
    
    .list{
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
 
    #hamburger:checked ~ .menu{
        max-height: 100%;
    }
    
    .hamburger {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;       
    }

    .hamburger .line {
        display: block;
        width: 40px;
        height: 3px;
        background-color: #ecf0f1;
        margin-block: 10px;
        border-radius: 4px;
        transition: -webkit-transform .5s;
        transition: transform .5s;
        transition: transform .5s, -webkit-transform .5s;
        opacity: .25s;
    }

    .hamburger.active .line:nth-child(1) {
        -webkit-transform: translateY(13px)
        rotate(45deg);
                transform: translateY(13px)
        rotate(45deg);
    }

    .hamburger.active .line:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .line:nth-child(3) {
        -webkit-transform: translateY(-13px)
        rotate(-45deg);
                transform: translateY(-13px)
        rotate(-45deg);
    }
}

.about__me {
    width: 250px;
    max-width: 100%;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items:center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    -webkit-transform: rotate(0);
            transform: rotate(0);
}
.about__container{
    display: grid;
    grid-template-columns: 25% 35%;
    grid-gap: 0%;
    gap: 0%;
}

.about__text {
    font-size: 1rem;
    display: grid;
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align:left;
    transition: var(--transition);
}

.about__text:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.aboutTitle {
    padding-top: 10rem;
    text-align: center;
}

.abouttitle {
    color: var(--color-primary);
}

/* ========= MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .about__container {
        height: 68vh;
    }
}

/* ========= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .about__container {
        height: 100vh;
        margin: 0;
        padding: 0;
    }
}
.projecttitle{
    padding-top: 3rem;
    text-align: center;
    color: var(--color-primary)
}
.h2s{
    color: white;
}

.portfolio__container {
    padding-right: 18rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5rem;
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}
.card {
    position: relative;
    padding: 1.3rem;
    border-radius: 1.5rem;
    background:url(/static/media/unipass.a77e8852.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
}
.card2 {
    position: relative;
    padding: 1.3rem;
    border-radius: 1.5rem;
    background:url(/static/media/calendar1.334f05e4.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
}
.card3 {
    position: relative;
    padding: 1.3rem;
    border-radius: 1.5rem;
    background:url(/static/media/personal2.ad10350d.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
}

.card::before, .card2::before, .card3::before,
.card::after, .card2::after, .card3::after {
    content:'';
    position: absolute;
    left:0;
    width: 100%;
    height: calc(60% + 35px);
    background-color: var(--color-white);
    transition: -webkit-transform .5s .25s ease-in;
    transition: transform .5s .25s ease-in;
    transition: transform .5s .25s ease-in, -webkit-transform .5s .25s ease-in;
    z-index: -1;
}
.card::before,.card2::before, .card3::before {
    top:0;
    -webkit-clip-path:polygon(0 0, 100% 0, 100% 45%, 0% 100%);
            clip-path:polygon(0 0, 100% 0, 100% 45%, 0% 100%);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
}
.card::after, .card2::after, .card3::after{
    bottom: 0;
    -webkit-clip-path: polygon(0 55%, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 55%, 100% 0, 100% 100%, 0% 100%);
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}
.card__content{
    text-align: center;
    padding: 2rem;
    color: black;
    opacity: 0;
    transition: opacity .25s;
}
.card:hover::before, .card2:hover::before, .card3:hover::before,
.card:hover::after, .card2:hover::after, .card3:hover::after {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.card:hover .card__content, .card2:hover .card__content, .card3:hover .card__content {
    opacity: 1;
    transition-delay: .75s;
    cursor:default;
}

@media screen and (max-width: 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.2rem;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
}
.contactTitle {
    text-align: center;
    
    padding-top: 7rem;
}
.contacttitle2 {
    color: var(--color-primary);
}

.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    grid-gap: 12%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* =============== FORM ============= */
form {
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    position: relative;
}

input, textarea {
  padding: 10px;
  border: none;
  border-radius: 4px;
  font: inherit;
  color: #fff;
  background-color: transparent;
  outline: 2px solid var(--color-white);
  resize: none;
}

input:is(:focus, :valid) {
    outline-color: var(--color-bg-variant);
}

textarea:is(:focus, :valid) {
    outline-color: var(--color-bg-variant);
}

::-webkit-input-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
    -webkit-transition: -webkit-transform .25s;
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s;
    color: var(--color-white);
}

:-ms-input-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10px, 10px);
    -ms-transition: transform .25s;
    transition: transform .25s;
    color: var(--color-white);
}

::placeholder {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(10px, 10px);
            transform: translate(10px, 10px);
    transition: -webkit-transform .25s;
    transition: transform .25s;
    transition: transform .25s, -webkit-transform .25s;
    color: var(--color-white);
}

.alert-container {
    position: absolute;
    top: 23rem;
    left: 8rem;
  }
.alert-inner {
    display: inline;
    padding: 8px 16px;
    z-index: 1;
  }

/*
input:focus+::placeholder,
input:valid+::placeholder {
    transform: translate(10px, -14px) scale(.8);
    color: var(--color-bg-variant);
    padding-inline: 5px;
    background-color: var(--color-bg);
} */


/* ======================= MEDIA QUERIES (MEDIUM DEVICES) ================ */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        gap: 2rem;
    }
}

/* ======================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}
footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 4rem;
}
.h4 {
    display: inline-block;
    color: black;
}

.footer__socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}

.follow__me {
    margin-bottom: 1em;
    color: black;
}
/* ======================= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .permalinks {
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }
    
    .footer__socials {
        margin-bottom: 2.6rem;
    }
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #345ea8;
    --color-bg-variant:hsla(204, 64%, 44%, 0.815);
    --color-primary: #4ab4de;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-ms: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #345ea8;
    background: var(--color-bg);
    color: #fff;
    color: var(--color-white);
    line-height: 1.7;
}

/* ============= GENERAL STYLES ================== */
.container {
    width: 75%;
    width: var(--container-width-lg);
    margin-left: 20%;
    margin-right: 20%;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
    height: 100vh;
}

section > h2, section > h5 {
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    color: var(--color-light);
}

section > h2 {
    color: #4ab4de;
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: rgba(255, 255, 255, 0.6);
    color: var(--color-light);
}

a {
    color: #4ab4de;
    color: var(--color-primary);
    transition: all 400ms ease;
    transition: var(--transition);
}

a:hover {
    color: #fff;
    color: var(--color-white);
}

.btn {
    width: -webkit-max-content;
    width: max-content;
    display: inline-block;
    color: #345ea8;
    color: var(--color-bg);
    background: #4ab4de;
    background: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid #4ab4de;
    border: 1px solid var(--color-primary);
    transition: all 400ms ease;
    transition: var(--transition);
}

.btn:hover {
    background: #fff;
    background: var(--color-white);
    color: #345ea8;
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: #345ea8;
    background: var(--color-bg);
    color: #4ab4de;
    color: var(--color-primary);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ============= HEADER SOCIALS ============*/
.FloatingSocials{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    position: fixed;
    left: 0;
    bottom: 0rem;
    margin-left: 2%;
}

.FloatingSocials::after {
    content: '';
    width: 0px;
    height: 25rem;
    background: #4ab4de;
    background: var(--color-primary);
}

/* ================== HEADER EMAIL ===========*/
/*
.FloatingEmail {
    display: flex;
    gap: 0.8rem;
    position: fixed;
    left: 0;
    bottom: 0rem;
    letter-spacing: 0.1em;
    writing-mode:vertical-lr;
}
.FloatingEmail::before {
    content: '';
    width: 0px;
    height: 150px;
    margin: 0px auto;
    background: var(--color-primary);
}
.FloatingEmail::after {
    content: '';
    width: 0px;
    height: 150px;
    margin: 0px auto;
    background: var(--color-primary);
} */

/* ========= MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .container{
        width: 86%;
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ========= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-top: 2rem;
    }
    .FloatingSocials, .FloatingEmail {
        display: none;
    }
}
