.about__me {
    width: 250px;
    max-width: 100%;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items:center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}
.about__container{
    display: grid;
    grid-template-columns: 25% 35%;
    gap: 0%;
}

.about__text {
    font-size: 1rem;
    display: grid;
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align:left;
    transition: var(--transition);
}

.about__text:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.aboutTitle {
    padding-top: 10rem;
    text-align: center;
}

.abouttitle {
    color: var(--color-primary);
}

/* ========= MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    .about__container {
        height: 68vh;
    }
}

/* ========= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .about__container {
        height: 100vh;
        margin: 0;
        padding: 0;
    }
}