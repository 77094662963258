.projecttitle{
    padding-top: 3rem;
    text-align: center;
    color: var(--color-primary)
}
.h2s{
    color: white;
}

.portfolio__container {
    padding-right: 18rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}
.card {
    position: relative;
    padding: 1.3rem;
    border-radius: 1.5rem;
    background:url('../../assets/unipass.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
}
.card2 {
    position: relative;
    padding: 1.3rem;
    border-radius: 1.5rem;
    background:url('../../assets/calendar1.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
}
.card3 {
    position: relative;
    padding: 1.3rem;
    border-radius: 1.5rem;
    background:url('../../assets/personal2.png');
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
}

.card::before, .card2::before, .card3::before,
.card::after, .card2::after, .card3::after {
    content:'';
    position: absolute;
    left:0;
    width: 100%;
    height: calc(60% + 35px);
    background-color: var(--color-white);
    transition: transform .5s .25s ease-in;
    z-index: -1;
}
.card::before,.card2::before, .card3::before {
    top:0;
    clip-path:polygon(0 0, 100% 0, 100% 45%, 0% 100%);
    transform: translateY(-100%);
}
.card::after, .card2::after, .card3::after{
    bottom: 0;
    clip-path: polygon(0 55%, 100% 0, 100% 100%, 0% 100%);
    transform: translateY(100%);
}
.card__content{
    text-align: center;
    padding: 2rem;
    color: black;
    opacity: 0;
    transition: opacity .25s;
}
.card:hover::before, .card2:hover::before, .card3:hover::before,
.card:hover::after, .card2:hover::after, .card3:hover::after {
    transform: translateY(0);
}
.card:hover .card__content, .card2:hover .card__content, .card3:hover .card__content {
    opacity: 1;
    transition-delay: .75s;
    cursor:default;
}

@media screen and (max-width: 1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}