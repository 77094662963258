header {
    height: 100vh;
    padding-top: 15rem;
    overflow: hidden;
}
/*
.me {
    width: 700px;
    clip-path: circle();
    max-width: 100%;
    margin-right: 20px;
}
*/
p{
    display: inline-block;
}

.header__container {
    display: flex;
    padding-left: 11rem;
}
.name {
    color: var(--color-primary);
}


/* ================= CTA ====================*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: left;
}

/* ========= LINES ========== */
.line {
    width: 60%;
    height: 1px;
    border: 0 none;
    margin-right: auto;
    margin-left: auto;
    background-color: var(--color-white);
}

/* ========= MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* ========= MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        margin: 0;
        padding: 0;
    }
}